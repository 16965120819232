import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './brands-block.module.scss';

const query = graphql`
  query GET_LOGOS {
    forbes: file(relativePath: { eq: "about-us/forbes-logo.png" }) {
      childImageSharp {
        fixed(width: 93) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    entrepreneur: file(relativePath: { eq: "about-us/entrepreneur.png" }) {
      childImageSharp {
        fixed(width: 146) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    yahoonews: file(relativePath: { eq: "about-us/yahoo-news-light.png" }) {
      childImageSharp {
        fixed(width: 146) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    abc_news: file(relativePath: { eq: "about-us/abc-news-solid-black-logo.png" }) {
      childImageSharp {
        fixed(width: 144) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    business_insider: file(relativePath: { eq: "about-us/business-insider-logo.png" }) {
      childImageSharp {
        fixed(width: 103) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    usa_today: file(relativePath: { eq: "about-us/usa-today-logo.png" }) {
      childImageSharp {
        fixed(width: 131) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const BrandsBlock = () => {
  const data = useStaticQuery(query);
  const titleTxt = 'As seen on';

  return (
    <div className={styles.brandsFeed}>
      <h5 className={styles.title}>{titleTxt}</h5>
      <div className={styles.brands}>
        {Object.values(data).map(({ childImageSharp }) => (
          <div key={childImageSharp.fixed.src}>
            <div className={styles.slideWrapper}>
              <Img className={styles.slideImg} fixed={childImageSharp.fixed} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandsBlock;
