import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
import SEO from '../components/seo';
import Layout from '../components/layout';
import BrandsBlock from '../components/brands-block';
import styles from '../styles/pages/about-us.module.scss';
import { isIbiInstances, isNewIbiUsaInstance } from '../helpers/utils';

const query = graphql`
  query {
    image1: file(relativePath: { eq: "about-us/image1.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "about-us/image2.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "about-us/image3.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const AboutUs = () => {
  const data = useStaticQuery(query);
  const influenceText1 = isIbiInstances ? 'publisher’s' : 'affiliate marketing’s';
  const influenceText2 = isIbiInstances ? 'publishers' : 'affiliate marketing';

  return (
    <Layout fluid pageWrapperClassName={styles.page_wrapper}>
      <SEO title="About us" />
      <div className={clsx(styles.blockWrapper, styles.topBlock)}>
        <div className={styles.textWrapper}>
          <h1>Empowering brands and marketers to grow online.</h1>
          <p>
            We sell digital products from trusted brands. Our handpicked products and services
            improve the lives of customers around the world.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image1.childImageSharp.fluid} />
        </div>
      </div>
      {!isNewIbiUsaInstance && <BrandsBlock />}
      <div className={clsx(styles.blockWrapper, styles.reverse)}>
        <div className={styles.textWrapper}>
          <p>
            Our mission is to provide a source of income to anyone, regardless of their background,
            experience, and skillset, by simply being connected to the internet via their mobile
            phones.
          </p>
          <p>
            {`How do we do it? By never slowing down. By constantly innovating. By connecting global
            brands to our industry-leading ${influenceText1} network. By using a proven
            strategy, shaped by years of experience.`}
          </p>
        </div>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image2.childImageSharp.fluid} />
        </div>
      </div>
      <div className={styles.blockWrapper}>
        <div className={styles.textWrapper}>
          <h1>Be part of our network!</h1>
          <p>
            {`Our platform, combined with phenomenal support, provides our partners with everything
            they need to grow their online business using ${influenceText2}. Brands and
            publishers can boost their revenue with our cutting-edge, user-friendly network
            interface.`}
          </p>
          <Link to="/sign-up-2" className={styles.link}>
            Sign Up
          </Link>
        </div>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image3.childImageSharp.fluid} />
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
